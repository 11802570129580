import axios from 'axios';

const microservicesApi = 'https://microservice.horizonlabs.fr';

const getSaleStage = () => {
    return '-1';
    return axios.get(`${microservicesApi}/gnogen-lottery/sale-stage`).then((res) => {
        return Buffer.from(res.data.data.returnData[0], 'base64').toString('hex');
    }).catch(() => {
        return '-1';
    });
};

export {
    getSaleStage,
};