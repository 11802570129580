/* eslint-disable */
import * as React from 'react';
import { useGetAccountInfo, useGetNetworkConfig, useTrackTransactionStatus } from '@elrondnetwork/dapp-core/hooks';
import { sendTransactions } from '@elrondnetwork/dapp-core/services';
import { logout } from '@elrondnetwork/dapp-core/utils';
import { refreshAccount } from '@elrondnetwork/dapp-core/utils/account';
import { ApiNetworkProvider } from "@elrondnetwork/erdjs-network-providers";
import { contractAddress, ticketsPrice, buyingPeriodStart, maxTickets } from 'config';
import { AddressValue, ContractFunction, Query, ResultsParser, SmartContract } from '@elrondnetwork/erdjs/out/smartcontracts';
import { Address } from '@elrondnetwork/erdjs/out';
import { routeNames } from 'routes';
import moment from 'moment';
import { useInterval } from 'usehooks-ts';
import { getSaleStage } from 'utils/api/microservices';

const Mint = () => {
  const [loading, setLoading] = React.useState<boolean>(true);

  const { network } = useGetNetworkConfig();
  let networkProvider = new ApiNetworkProvider("https://api.elrond.com", {timeout: 5000});
  const { address } = useGetAccountInfo();
  const isLoggedIn = Boolean(address);
  const [transactionSessionId, setTransactionSessionId] = React.useState<string | null>(null);

  const [isWhitelist, setIsWhitelist] = React.useState<boolean | null>(null);
  const [nftToBuy, setNftToBuy] = React.useState<number>(1);
  const [maxBuyable, setMaxBuyable] = React.useState<number>(maxTickets);
  const [ticketPrice, setTicketPrice] = React.useState<number>(ticketsPrice);
  const [alreadyBought, setAlreadyBought] = React.useState<boolean>(false);
  const [buyingPeriodStarted, setBuyingPeriodStarted] = React.useState<boolean>(false);
  const [saleStage, setSaleStage] = React.useState<number>(1);

  const getSaleStageFromApi = async () => {
    const tempSaleStage = await getSaleStage();
    if(tempSaleStage == '-1') {
      const tempSaleStage = await backupSaleStage();
      setSaleStage(tempSaleStage);
    } else {
      setSaleStage(parseInt(tempSaleStage,16));
    }
  };

  const backupSaleStage = async () => {
    let parsedContractAddress = new Address(contractAddress);
    let contract = new SmartContract({ address: parsedContractAddress });
    
    let query = contract.createQuery({
        func: new ContractFunction("getSaleStage")
    });
    
    let queryResponse = await networkProvider.queryContract(query);
    const [encoded] = queryResponse.returnData;
    switch (encoded) {
      case undefined: return 0; break;
      case '': return 0; break;
      default: {
        const decoded = Buffer.from(encoded, 'base64').toString('hex');
        return parseInt(decoded, 16);
        break;
      }
    }
  }

  // Check ig buying period as started
  useInterval(
    () => {
      if (buyingPeriodStart <= moment().unix()) {
        setBuyingPeriodStarted(true);
      }
    },
    1000,
  );

  React.useEffect(() => {
    if (isWhitelist) {
      getTicketsBuyableWhitelist();
    }
  }, [isWhitelist]);

  React.useEffect(() => {
    if (alreadyBought || saleStage > 1) {
      window.location.href = routeNames.buy;
    }
  }, [alreadyBought]);

  React.useEffect(() => {
    getSaleStageFromApi();
    getNumberOfTicketsBought();
    getIsWhitelist();
  }, []);

  // Get if the user has already bought some tickets
  const getNumberOfTicketsBought = async () => {
    let parsedContractAddress = new Address(contractAddress);
    let contract = new SmartContract({ address: parsedContractAddress });
    let callerAddress = new Address(address);

    let query = contract.createQuery({
      func: new ContractFunction("getTicketsBoughtForAddress"),
      args: [new AddressValue(callerAddress)]
    });

    let queryResponse = await networkProvider.queryContract(query);
    const [encoded] = queryResponse.returnData;
    switch (encoded) {
      case undefined: setAlreadyBought(false); break;
      case '': setAlreadyBought(false); break;
      default: {
        const decoded = Buffer.from(encoded, 'base64').toString('hex');
        setAlreadyBought(parseInt(decoded, 16) > 0 ? true : false);
        break;
      }
    }
  }

  function handleLogout() {
    logout(`${window.location.origin}`);
  }

  const nftMoins = () => {
    if (nftToBuy > 1) {
      setNftToBuy(nftToBuy - 1);
    }
  };

  const nftPlus = () => {
    if (nftToBuy < maxBuyable) {
      setNftToBuy(nftToBuy + 1);
    }
  };

  const nftMax = () => {
    setNftToBuy(maxBuyable);
  };

  // Get if the user can direct buy
  const getIsWhitelist = async () => {
    let parsedContractAddress = new Address(contractAddress);
    let contract = new SmartContract({ address: parsedContractAddress });
    let callerAddress = new Address(address);

    let query = contract.createQuery({
      func: new ContractFunction("isWhitelist"),
      args: [new AddressValue(callerAddress)]
    });

    let queryResponse = await networkProvider.queryContract(query);
    const [encoded] = queryResponse.returnData;
    switch (encoded) {
      case undefined: setIsWhitelist(false); break;
      case '': setIsWhitelist(false); break;
      default: {
        const decoded = Buffer.from(encoded, 'base64').toString('hex');
        setIsWhitelist(parseInt(decoded, 16) > 0 ? true : false);
        break;
      }
    }
  };
  const getTicketsBuyableWhitelist = async () => {
    let parsedContractAddress = new Address(contractAddress);
    let contract = new SmartContract({ address: parsedContractAddress });
    let callerAddress = new Address(address);

    let query = contract.createQuery({
      func: new ContractFunction("getTicketsBuyableForAddress"),
      args: [new AddressValue(callerAddress)]
    });

    let queryResponse = await networkProvider.queryContract(query);
    const [encoded] = queryResponse.returnData;
    switch (encoded) {
      case undefined:
        setMaxBuyable(0);
        setNftToBuy(0);
        break;
      case '':
        setMaxBuyable(0);
        setNftToBuy(0);
        break;
      default: {
        const decoded = Buffer.from(encoded, 'base64').toString('hex');
        setMaxBuyable(parseInt(decoded, 16));
        setNftToBuy(1);
        break;
      }
    }
  };

  const sendPurchaseTransaction = async () => {
    const calculateGas = 5000000+750000*nftToBuy > 599000000 ? 599000000 : 5000000+750000*nftToBuy;
    const revealTransaction = {
      value: 1000000000000000000 * ticketPrice * nftToBuy,
      data: `buyTickets@${nftToBuy.toString(16).length % 2 == 1 ? '0' + nftToBuy.toString(16) : nftToBuy.toString(16)}`,
      gasLimit: calculateGas,
      receiver: contractAddress
    };
    await refreshAccount();

    const { sessionId /*, error*/ } = await sendTransactions({
      transactions: revealTransaction,
      transactionsDisplayInfo: {
        processingMessage: 'Processing ticket(s) purchase',
        errorMessage: 'An error has occured',
        successMessage: 'Purchase successful'
      },
      redirectAfterSign: false
    });
    if (sessionId != null) {
      setTransactionSessionId(sessionId);
    }
  };

  const transactionStatus = useTrackTransactionStatus({
    transactionId: transactionSessionId
  });

  React.useEffect(() => {
    if (transactionStatus.isSuccessful) {
      window.location.href = routeNames.buy;
    }
  }, [transactionStatus.isPending]);

  return (
    <div className='container'>
      <div className='row mt-5'>
        <div className='col-12 col-lg-11'>
          <div className='row'>
            <div className='col-lg-9 col-md-12'>
              <div className='p-40'>
                <p className='gnogen-text gnogen-mint-main-text gnogen-text-shadow fs-1'>How many <span className='gnogen-yellow'>lottery</span> tickets would you like to purchase ?</p>
                <p className='gnogen-text fs-20'>MAX ALLOWED : <span className='gnogen-yellow'>{maxBuyable}</span></p>
              </div>
            </div>
            <div className='col-lg-3 col-md-12'>
              <div className='gnogen-mint-select_container justify-content-center justify-content-lg-end me-sm-5'>
                <button className='btn gnogen-text mr-4 fs-75 gnogen-text-shadow font-weight-bold' onClick={nftMoins}>-</button>
                <p className='mb-0 gnogen-text fs-59 gnogen-text-shadow font-weight-bold'>{nftToBuy}</p>
                <button className='btn gnogen-text ml-4 fs-59 gnogen-text-shadow font-weight-bold' onClick={nftPlus}>+</button>
                <button className='btn gnogen-max-button text-roboto' onClick={nftMax}>MAX</button>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-6 col-md-4'> </div>
            <div className='col-lg-6 col-md-8 col-sm-12 text-end d-flex flex-column'>
              <div className='gnogen-border-top mb-5 me-md-5'></div>
              <p className='gnogen-text gnogen-mint-total-price me-5 whitespace-nowrap'>
                <span className='font-weight-medium'>TOTAL</span> <span className='font-weight-bold text-end'><span className='gnogen-yellow'>{(ticketPrice * nftToBuy).toFixed(2)}</span> EGLD</span></p>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-8 col-md-2 col-sm-0'> </div>
            <div className='col-lg-4 col-md-10 col-sm-12 text-center text-lg-end'>
              <button className='btn gnogen-connect-button mt-3 mt-lg-5 me-sm-2 me-lg-5' onClick={sendPurchaseTransaction} disabled={!buyingPeriodStarted || maxBuyable == 0}>COMPLETE PURCHASE</button>
              <button className='btn gnogen-connect-button mt-3 mt-lg-4 me-lg-5' onClick={handleLogout}>DISCONNECT WALLET</button>
            </div>
          </div>
        </div>
        <div className='col-1'>
          <p className='gnogen-text-minting font-weight-bolder d-none d-lg-flex'>
            MINTING
          </p>
        </div>
      </div>
    </div>
  );
};

export default Mint;