/* eslint-disable */
import React from 'react';
import { ExtensionLoginButton, LedgerLoginButton, WalletConnectLoginButton, WebWalletLoginButton  } from '@elrondnetwork/dapp-core/UI';
import { getIsLoggedIn } from '@elrondnetwork/dapp-core/utils';
import { routeNames } from 'routes';
import {ReactComponent as DefiWallet} from '../../assets/icon/defi-wallet.svg';
import {ReactComponent as LedgerIcon} from '../../assets/icon/ledger.svg';
import {ReactComponent as MaiarIcon} from '../../assets/icon/maiar.svg';

export const Unlock: () => JSX.Element = () => {

  React.useEffect(() => {
    const isLoggedIn = getIsLoggedIn();
    if (isLoggedIn) {
      window.location.href = routeNames.redirect;
    }
  }, []);

  return (
    <div className='d-flex align-middle w-100'>
      <div className='m-auto'>
        <div className='card my-4 text-center unlock-card'>
          <div className='card-body pt-5 pb-5 d-grid col-10 mx-auto'>
            <ExtensionLoginButton
              callbackRoute={routeNames.redirect}
              loginButtonText={'Maiar DeFi Wallet'}
              className='btn btn-block btn-connect'
              shouldRenderDefaultCss={false}
              logoutRoute='/'
            >
              <DefiWallet className='unlock-icon'/> Maiar DeFi Wallet
            </ExtensionLoginButton>

            <WalletConnectLoginButton
              callbackRoute={routeNames.redirect}
              loginButtonText={'Maiar'}
              className='btn btn-block btn-connect'
              shouldRenderDefaultCss={false}
              logoutRoute='/'
            >
              <MaiarIcon className='unlock-icon'/> Maiar
            </WalletConnectLoginButton>
            <WebWalletLoginButton
              callbackRoute={routeNames.redirect}
              loginButtonText={'Maiar'}
              className='btn btn-block btn-connect'
              shouldRenderDefaultCss={false}
            >
              <MaiarIcon className='unlock-icon'/> Web Wallet
            </WebWalletLoginButton> 
            <LedgerLoginButton
              callbackRoute={routeNames.home}
              loginButtonText={'Ledger'}
              className='btn btn-block btn-connect'
              shouldRenderDefaultCss={false}
            >
              <LedgerIcon className='unlock-icon'/> Ledger
            </LedgerLoginButton>
            <div className='text-center text-light mt-5 text-roboto text-dark'>
              <p>New to Elrond?</p>
              <a href='https://wallet.elrond.com/create' className='text-dark font-weight-bold'>Learn How to set up a wallet</a>
            </div>
            </div>
        </div>
      </div>
    </div>
  );
};

export default Unlock;
