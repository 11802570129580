import React from 'react';
import { SignTransactionsModals, NotificationModal, TransactionsToastList } from '@elrondnetwork/dapp-core/UI';
import { DappProvider } from '@elrondnetwork/dapp-core/wrappers';
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';
import Layout from 'components/Layout';
import { environment } from 'config';
import PageNotFound from 'pages/PageNotFound';
import routes from 'routes';

const App = () => {
  return (
    <Router>
      <DappProvider
        environment={environment}
      >
          <Layout>
            <NotificationModal />
            <SignTransactionsModals className="gnogen-sign-transaction" />
            <TransactionsToastList />
            <Routes>
              {routes.map((route: any, index: number) => (
                <Route
                  path={route.path}
                  key={'route-key-' + index}
                  element={<route.component />}
                />
              ))}
              <Route path='*' element={<PageNotFound />} />
            </Routes>
          </Layout>
      </DappProvider>
    </Router>
  );
};

export default App;
