import React from 'react';

const Footer = () => {
  return (
    <footer className='text-center mb-2 mt-5 text-roboto ps-4 pe-4'>
      <div className='gnogen-footer'>
        <p className='float-start'>© 2022 GNOGEN. ALL RIGHTS RESERVED.</p>
        <ul className='float-end'>
          <li><a>TERMS & CONDITIONS</a></li>
          <li><a>PRIVACY</a></li>
          <li><a>DISCLAIMER</a></li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
