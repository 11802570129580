/* eslint-disable */
import * as React from 'react';
import { routeNames } from 'routes';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';


const Home = () => {
  const MINT_ENABLED = true;

  return (
    <div className='container'>
      <div className='row mt-sm-5'>
        <div className='col-md-12 col-lg-1 d-md-flex d-lg-none justify-content-center'>
            {MINT_ENABLED ?
              <Link to={routeNames.unlock} className='text-mint-now-md'>
                MINT NOW
              </Link> :
              <>
                <p className='text-mint-now-md' data-tip="COMING SOON">
                  MINT NOW
                </p>
                <ReactTooltip />
              </>
            }
        </div>
        <div className='col-md-12 col-lg-11'>
          <div className='row mint-information'>
            <div className='col-7 col-lg-5 text-end'>
              <p className='fs-next-mint mb-0'>NEXT MINT</p>
              <p className='fs-45 mb-0'>PRICE</p>
              <p className='fs-45 mb-0'>DATE</p>
              <p className='fs-45 mb-0'>MINTAGE</p>
            </div>
            <div className='col-1'></div>
            <div className='col-4 col-lg-6 gnogen-yellow'>
              <p className='fs-next-mint mb-0'>DOGA ACT 7</p>
              <p className='fs-45 mb-0'>0.5 <span className="d-none d-sm-inline">EGLD</span></p>
              <p className='fs-45 mb-0'>11/11</p>
              <p className='fs-45 mb-0'>777</p>
            </div>
          </div>
          <div className='row'>
            <div className='col-12'>
              <div className='gnogen-video-container mt-5 mb-5'>
                <video loop autoPlay playsInline muted className="gnogen-video">
                  <source src={process.env.PUBLIC_URL + '/assets/Doga_Act_7_1st_Edition_FINAL.mp4'} type="video/mp4"/>
                </video>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-12 text-center'>
              <p className='text-roboto gnogen-text'>Gnogen’s Second Supreme hero is finally here. So smol, much wow.</p>
              <p className='text-roboto gnogen-text'>Written by Joshua Kim. Illustration by Josh Adams {'&'} Lisa B. Produced by Gnogen.</p>
            </div>
          </div>
        </div>
        <div className='col-md-12 col-lg-1 d-none d-lg-flex'>
            {MINT_ENABLED ?
              <Link to={routeNames.unlock} className='text-mint-now'>
                MINT NOW
              </Link> :
              <>
                <p className='text-mint-now' data-tip="COMING SOON">
                  MINT NOW
                </p>
                <ReactTooltip />
              </>
            }
        </div>
      </div>
    </div>
  );
};

export default Home;