/* eslint-disable */
import * as React from 'react';
import { useGetAccountInfo, useGetNetworkConfig } from '@elrondnetwork/dapp-core/hooks';
import { Link } from 'react-router-dom';
import { routeNames } from 'routes';

const Lottery = () => {
  const { network } = useGetNetworkConfig();
  
  const { address } = useGetAccountInfo();
  const isLoggedIn = Boolean(address);

  return (
<div className="container">
      <div className="row text-start gnogen-lottery-title_container">
        <div className='col-12'>
          <p className='gnogen-yellow gnogen-lottery-title'>LOTTERY</p>
        </div>
      </div>
      <div className="row text-center gnogen-lottery-title_container">
        <div className='col-12'>
          <p className='gnogen-lottery-title'>DROP</p>
        </div>
      </div>
      <div className="row text-end gnogen-lottery-title_container">
        <div className='col-12'>
          <p className='gnogen-lottery-title'>SYSTEM</p>
          </div>
      </div>
      <div className='row gnogen-rules-container'>
        <div className='col-12'>
          <p className='text-roboto fs-25 font-weight-bold'>Entry Rules & Regulations</p>
          <p className='gnogen-text-rules'><span className='gnogen-yellow'>/</span> For every Emidas NFT you own (snapshot already taken), you may purchase up to 5 lottery tickets each (max 40 tickets per wallet).</p>
          <p className='gnogen-text-rules'><span className='gnogen-yellow'>/</span> If you do not own an Emidas NFT, you may purchase 4 lottery tickets.</p>
          <p className='gnogen-text-rules'><span className='gnogen-yellow'>/</span> Please be sure to purchase all desired tickets your first time through the line.</p>
          <p className='gnogen-text-rules'><span className='gnogen-yellow'>/</span> Even if you eligible to purchase additional tickets, the smart contract will not allow you to re-enter the line.</p>
          <p className='gnogen-text-rules'><span className='gnogen-yellow'>/</span> If your ticket is selected as a winner, check back here after the results are announced to claim your NFT.</p>
          <p className='gnogen-text-rules'><span className='gnogen-yellow'>/</span> If your ticket is not selected, check back here after the results are announced to reclaim your $EGLD.</p>
          <p className='gnogen-text-rules'><span className='gnogen-yellow'>/</span> Lottery results will be announced in 48 hours!</p>
        </div>
      </div>
      <div className='row'>
        <div className='col-12 text-center'>
          <Link to={routeNames.mint} className='btn gnogen-connect-button' >NEXT</Link>
        </div>
      </div>
    </div>
  );
};

export default Lottery;