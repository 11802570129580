/* eslint-disable */

import React from 'react';
import { Container, Nav, Navbar as BsNavbar } from 'react-bootstrap';

const Navbar = () => {
  return (
<header>
    <BsNavbar className='gnogen-navbar' bg="none" expand="lg">
      <Container>
      <BsNavbar.Toggle aria-controls="basic-navbar-nav" />
      <BsNavbar.Collapse id="basic-navbar-nav">
        <Nav className="me-auto">
          <Nav.Item><a href="https://gnogen.io"><img className="gnogen-logo " src={process.env.PUBLIC_URL + '../gnogen-logo.png'}/></a></Nav.Item>
          <Nav.Item><a href="https://gnogen.gitbook.io">ABOUT</a></Nav.Item>
          <Nav.Item><a href="https://gnogen.io/collections">COLLECTIONS</a></Nav.Item>
          <Nav.Item><span className='gnogen-yellow'>/</span> SHOP <span className='gnogen-yellow'>/</span></Nav.Item>
          <Nav.Item><a href="https://gnogen.io/partners">DAO</a></Nav.Item>
          <Nav.Item><a href="https://gnogen.io/elrond">ELROND</a></Nav.Item>
          <Nav.Item><a href="/">GNOGENVERSE</a></Nav.Item>
          </Nav>
        </BsNavbar.Collapse>
        </Container>
      </BsNavbar>
    </header>
  );
};

export default Navbar;

/*    */