/* eslint-disable */
import * as React from 'react';
import { routeNames } from 'routes';
import { useGetAccountInfo, useGetNetworkConfig } from '@elrondnetwork/dapp-core/hooks';
import { ApiNetworkProvider } from '@elrondnetwork/erdjs-network-providers/out';
import { Address, AddressValue, ContractFunction, SmartContract } from '@elrondnetwork/erdjs/out';
import { contractAddress } from 'config';
import { getSaleStage } from 'utils/api/microservices';
import { Link } from 'react-router-dom';


const Redirect = () => {

    const { network } = useGetNetworkConfig();
    let networkProvider = new ApiNetworkProvider(network.apiAddress, {timeout: 5000});
    const { address } = useGetAccountInfo();
    const isLoggedIn = Boolean(address);

    const [isWhitelist, setIsWhitelist] = React.useState<boolean | null>(null);
    const [hasBuyDoga, setHasBuyDoga] = React.useState<boolean | null>(null);
    const [saleStage, setSaleStage] = React.useState<number | null>(null);
    const [alreadyBought, setAlreadyBought] = React.useState<boolean| null>(null);

    const getSaleStageFromApi = async () => {
        const tempSaleStage = await getSaleStage();
        if(tempSaleStage == '-1') {
          const tempSaleStage = await backupSaleStage();
          setSaleStage(tempSaleStage);
        } else {
          setSaleStage(parseInt(tempSaleStage,16));
        }
      };

      const backupSaleStage = async () => {
        let parsedContractAddress = new Address(contractAddress);
        let contract = new SmartContract({ address: parsedContractAddress });
        
        let query = contract.createQuery({
            func: new ContractFunction("getSaleStage")
        });
        
        let queryResponse = await networkProvider.queryContract(query);
        const [encoded] = queryResponse.returnData;
        switch (encoded) {
          case undefined: return 0; break;
          case '': return 0; break;
          default: {
            const decoded = Buffer.from(encoded, 'base64').toString('hex');
            return parseInt(decoded, 16);
            break;
          }
        }
      }

    const getHasBuyDoga = async () => {
        let parsedContractAddress = new Address(contractAddress);
        let contract = new SmartContract({ address: parsedContractAddress });
        let callerAddress = new Address(address);
        
        let query = contract.createQuery({
            func: new ContractFunction("hasBuyDoga"),
            args: [new AddressValue(callerAddress)]
        });
        
        let queryResponse = await networkProvider.queryContract(query);
        const [encoded] = queryResponse.returnData;
        switch (encoded) {
          case undefined: setHasBuyDoga(false); break;
          case '': setHasBuyDoga(false); break;
          default: {
            const decoded = Buffer.from(encoded, 'base64').toString('hex');
            setHasBuyDoga(parseInt(decoded, 16) > 0 ? true : false);
            break;
          }
        }
      };

      const getIsWhitelist = async () => {
        let parsedContractAddress = new Address(contractAddress);
        let contract = new SmartContract({ address: parsedContractAddress });
        let callerAddress = new Address(address);
        
        let query = contract.createQuery({
            func: new ContractFunction("isWhitelist"),
            args: [new AddressValue(callerAddress)]
        });
        
        let queryResponse = await networkProvider.queryContract(query);
        const [encoded] = queryResponse.returnData;
        switch (encoded) {
          case undefined: setIsWhitelist(false); break;
          case '': setIsWhitelist(false); break;
          default: {
            const decoded = Buffer.from(encoded, 'base64').toString('hex');
            setIsWhitelist(parseInt(decoded, 16) > 0 ? true : false);
            break;
          }
        }
      };

      const getNumberOfTicketsBought = async () => {
        let parsedContractAddress = new Address(contractAddress);
        let contract = new SmartContract({ address: parsedContractAddress });
        let callerAddress = new Address(address);
    
        let query = contract.createQuery({
          func: new ContractFunction("getTicketsBoughtForAddress"),
          args: [new AddressValue(callerAddress)]
        });
    
        let queryResponse = await networkProvider.queryContract(query);
        const [encoded] = queryResponse.returnData;
        switch (encoded) {
          case undefined: setAlreadyBought(false); break;
          case '': setAlreadyBought(false); break;
          default: {
            const decoded = Buffer.from(encoded, 'base64').toString('hex');
            setAlreadyBought(parseInt(decoded, 16) > 0 ? true : false);
            break;
          }
        }
      }

      React.useEffect(() => {
        getSaleStageFromApi();
        getHasBuyDoga();
        getIsWhitelist();
        getNumberOfTicketsBought();
      }, []);

      React.useEffect(() => {
        if (isWhitelist != null && hasBuyDoga != null && saleStage != null && alreadyBought != null) {
          if(alreadyBought || saleStage > 1){ window.location.href = routeNames.buy; return; }
          if(isWhitelist && !hasBuyDoga) { window.location.href = routeNames.directbuy; return; }
          if(hasBuyDoga && saleStage < 2) { window.location.href = routeNames.lottery; return; }
          window.location.href = routeNames.lottery;
        }
      }, [isWhitelist, hasBuyDoga, saleStage, alreadyBought]);
    

  return (
    <div className='container'>
      <div className='d-flex flex-column h-100 justify-content-center align-items-center' style= {{}}>
        <p className='fs-6 text-roboto'>REDIRECTING...</p>
        <p className='fs-6 text-roboto'>If you're not getting redirected click <Link to={routeNames.lottery}>here</Link>.</p>
      </div>
    </div>
  );
};

export default Redirect;