import React from 'react';
import { AuthenticatedRoutesWrapper } from '@elrondnetwork/dapp-core/wrappers';
import { useLocation } from 'react-router-dom';
import routes, { routeNames } from 'routes';
import Footer from './Footer';
import Navbar from './Navbar';

const Layout = ({ children }: { children: React.ReactNode }) => {
  const { search } = useLocation();
  return (
    <>
      <div className='d-flex flex-column min-vh-100'>
        <Navbar />
        <main className='flex-grow-1 d-flex'>
          <AuthenticatedRoutesWrapper
            routes={routes}
            unlockRoute={`${routeNames.home}${search}`}
          >
            {children}
          </AuthenticatedRoutesWrapper>
        </main>
        <Footer />
      </div>
    </>
  );
};

export default Layout;

/*      <video playsInline={true} autoPlay={true} loop={true} muted={true} className="video-background"/>*/
