/* eslint-disable */
import * as React from 'react';
import { useGetAccountInfo, useGetNetworkConfig, useTrackTransactionStatus } from '@elrondnetwork/dapp-core/hooks';
import { sendTransactions } from '@elrondnetwork/dapp-core/services';
import { logout } from '@elrondnetwork/dapp-core/utils';
import { refreshAccount } from '@elrondnetwork/dapp-core/utils/account';
import {
  Address,
  AddressValue,
  ContractFunction,
  Query,
  SmartContract
} from '@elrondnetwork/erdjs';
import { useInterval } from 'usehooks-ts';
import { contractAddress, salesContractAdresses, ticketsPrice, winningSelectionStart } from 'config';
import NumberEasing from 'utils/NumberEasing';
import { getSaleStage } from '../../utils/api/microservices';
import { ApiNetworkProvider } from '@elrondnetwork/erdjs-network-providers/out';
import { time } from 'console';
import { useSearchParams } from 'react-router-dom';

const Buy = () => {
  const { network } = useGetNetworkConfig();
  let networkProvider = new ApiNetworkProvider(network.apiAddress, {timeout: 5000});
  const { address } = useGetAccountInfo();
  const isLoggedIn = Boolean(address);
  const [transactionSessionId, setTransactionSessionId] = React.useState<string | null>(null);
  const [ searchParams, setSearchParams ] = useSearchParams();
  
  const [selectedContractAddress, setSelectedContractAddress] = React.useState<string>('');
  const [sellStage, setSellStage] = React.useState<number>(0);
  const [drawTime, setDrawTime] = React.useState<number>(winningSelectionStart);
  const [drawTimeLeft, setdrawTimeLeft] = React.useState<any>(null);
  const [countdownPhrase, setCountdownPhrase] = React.useState<string>('');
  const [numberTicketBought, setNumberTicketBought] = React.useState<number>(0);
  const [numberOfCurrentWinningTickets, setNumberOfCurrentWinningTickets] = React.useState<number>(0);
  const [hasClaimed, setHasClaim] = React.useState<boolean>(false);

  function handleLogout() {
    logout(`${window.location.origin}`);
  }

  const calculateDrawTimeLeft = () => {
    const difference = +new Date(drawTime * 1000) - +new Date();

    let timeLeft: any = null;

    if (difference > 0) {
      timeLeft = {
        hours: Math.floor((difference / (1000 * 60 * 60))),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      };
    }
    setdrawTimeLeft(timeLeft);
  };


  React.useEffect(() => {
    if (searchParams.get('act')) {
      const actIndex = parseInt(searchParams.get('act') || '') - 1;
      if (actIndex === undefined || isNaN(actIndex) || actIndex < 0 || actIndex >= salesContractAdresses.length) {
        setSelectedContractAddress(contractAddress);
        setSearchParams({act: salesContractAdresses.length.toString()});
      } else {
        setSelectedContractAddress(salesContractAdresses[actIndex]);
      }
    } else {
      setSelectedContractAddress(contractAddress);
    }
  }, []);

  React.useEffect(() => {
    if (selectedContractAddress !== '') {
      getSalesStage();
    }
  }, [selectedContractAddress]);

  const firstUpdate = React.useRef(true);
  React.useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    if (sellStage !== 0 && selectedContractAddress !== '') {
      getNumberOfTicketsBought();
      getNumberOfCurrentWinningTickets();
      getHasClaimed();
    }
  }, [sellStage, selectedContractAddress]);

  useInterval(
    () => {
      calculateDrawTimeLeft();
    },
    1000,
  );

  const getSalesStage = async () => {
    let saleStage = '';
    if (selectedContractAddress === contractAddress)
      saleStage = await getSaleStage();
    else
      saleStage = '-1';
    if (saleStage == '-1') {
      const saleStage = await backupSaleStage();
      setSellStage(saleStage);
    } else {
      setSellStage(parseInt(saleStage, 16));
    }
  };

  React.useEffect(() => {
    switch (sellStage) {
      case 0: setCountdownPhrase('AN ERROR OCCURED'); break;
      case 1: setCountdownPhrase('COUNTDOWN TO DRAWING'); break;
      case 2: case 3: setCountdownPhrase('WINNER PICKING, PLEASE WAIT..'); break;
      case 4: if (hasClaimed) { setCountdownPhrase('FINISHED'); } else { setCountdownPhrase('CLAIMING PHASE'); } break;
      default: setCountdownPhrase('AN ERROR OCCURED');
    }

  }, [sellStage, hasClaimed]);

  useInterval(
    () => {
      getSalesStage();
    },
    6000,
  );

  const getNumberOfTicketsBought = async () => {
    let parsedContractAddress = new Address(selectedContractAddress);
    let contract = new SmartContract({ address: parsedContractAddress });
    let callerAddress = new Address(address);

    let query = contract.createQuery({
      func: new ContractFunction("getTicketsBoughtForAddress"),
      args: [new AddressValue(callerAddress)]
    });

    let queryResponse = await networkProvider.queryContract(query);
    const [encoded] = queryResponse.returnData;
    switch (encoded) {
      case undefined: setNumberTicketBought(0); break;
      case '': setNumberTicketBought(0); break;
      default: {
        const decoded = Buffer.from(encoded, 'base64').toString('hex');
        setNumberTicketBought(parseInt(decoded, 16));
        break;
      }
    }
  };


  const backupSaleStage = async () => {
    let parsedContractAddress = new Address(selectedContractAddress);
    let contract = new SmartContract({ address: parsedContractAddress });

    let query = contract.createQuery({
      func: new ContractFunction("getSaleStage")
    });

    let queryResponse = await networkProvider.queryContract(query);
    const [encoded] = queryResponse.returnData;
    switch (encoded) {
      case undefined: return 0; break;
      case '': return 0; break;
      default: {
        const decoded = Buffer.from(encoded, 'base64').toString('hex');
        return parseInt(decoded, 16);
        break;
      }
    }
  }

  const getNumberOfCurrentWinningTickets = async () => {
    let parsedContractAddress = new Address(selectedContractAddress);
    let contract = new SmartContract({ address: parsedContractAddress });
    let callerAddress = new Address(address);

    let query = contract.createQuery({
      func: new ContractFunction("getNumberOfWinningTicketsForAddress"),
      args: [new AddressValue(callerAddress)]
    });

    let queryResponse = await networkProvider.queryContract(query);
    const [encoded] = queryResponse.returnData;
    switch (encoded) {
      case undefined: setNumberOfCurrentWinningTickets(0); break;
      case '': setNumberOfCurrentWinningTickets(0); break;
      default: {
        const decoded = Buffer.from(encoded, 'base64').toString('hex');
        setNumberOfCurrentWinningTickets(parseInt(decoded, 16));
        break;
      }
    }
  };

  const getHasClaimed = async () => {
    let parsedContractAddress = new Address(selectedContractAddress);
    let contract = new SmartContract({ address: parsedContractAddress });
    let callerAddress = new Address(address);

    let query = contract.createQuery({
      func: new ContractFunction("hasClaimed"),
      args: [new AddressValue(callerAddress)]
    });

    let queryResponse = await networkProvider.queryContract(query);
    const [encoded] = queryResponse.returnData;
    switch (encoded) {
      case undefined: setHasClaim(false); break;
      case '': setHasClaim(false); break;
      default: {
        const decoded = Buffer.from(encoded, 'base64').toString('hex');
        setHasClaim(parseInt(decoded, 16) > 0 ? true : false);
        break;
      }
    }
  };

  const sendClaimTransaction = async () => {
    const calculateGas = 20000000 + 4000000 * numberOfCurrentWinningTickets > 599000000 ? 599000000 : 20000000 + 4000000 * numberOfCurrentWinningTickets;
    const revealTransaction = {
      value: 0,
      data: 'claimNft',
      gasLimit: calculateGas,
      receiver: selectedContractAddress
    };
    await refreshAccount();

    const { sessionId /*, error*/ } = await sendTransactions({
      transactions: revealTransaction,
      transactionsDisplayInfo: {
        processingMessage: 'Claiming...',
        errorMessage: 'An error has occured',
        successMessage: 'Claim successful'
      },
      redirectAfterSign: false
    });
    if (sessionId != null) {
      setTransactionSessionId(sessionId);
    }
  };

  const transactionStatus = useTrackTransactionStatus({
    transactionId: transactionSessionId
  });

  React.useEffect(() => {
    if (transactionStatus.isSuccessful) {
      setHasClaim(true);
    }
  }, [transactionStatus.isPending]);

  return (
    <div className='container mt-5'>
      <div className="row text-center">
        <div className="col-12">
          <p className='text-roboto fs-3 text-break'><span className='gnogen-yellow'>WALLET ADDRESS : </span>{address}</p>
        </div>
        <div className="col-12">
          <p className='gnogen-countdown-title'>{countdownPhrase}</p>
        </div>
        <div className="col-12 gnogen-countdown_container">
          <p className='gnogen-countdown'>
            {drawTimeLeft && sellStage == 1 ?
              <>

                {`${drawTimeLeft.hours} : ${drawTimeLeft.minutes} : `}
                {drawTimeLeft.seconds.toString().length == 1 ? 0 : drawTimeLeft.seconds.toString().charAt(0)}
                <span className='gnogen-yellow'>
                  {drawTimeLeft.seconds.toString().length == 1 ? drawTimeLeft.seconds.toString().charAt(0) : drawTimeLeft.seconds.toString().charAt(1)}
                </span>
              </> :
              ''
            }
          </p>
        </div>
      </div>
      <div className="row d-none d-lg-flex">
        <div className="col-4 text-center">
          <p className='text-lato gnogen-buy-info gnogen-yellow'>{numberTicketBought}</p>
        </div>
        <div className="col-4 text-center">
          <p className='text-lato gnogen-buy-info'>{sellStage == 4 ? numberOfCurrentWinningTickets : 'TBA'}</p>
        </div>
        <div className="col-4 text-center">
          <p className='text-lato gnogen-buy-info'>{sellStage == 4 ?
            <>{hasClaimed ? 0 : (numberTicketBought - numberOfCurrentWinningTickets) * ticketsPrice}</> :
            'TBA'
          }
          </p>
        </div>
      </div>
      <div className="row d-none d-lg-flex">
        <div className="col-4 text-center">
          <p className='text-lato gnogen-buy-info'>TICKETS PURCHASED</p>
        </div>
        <div className="col-4 text-center">
          <p className='text-lato gnogen-buy-info'>WINNING TICKETS</p>
        </div>
        <div className="col-4 text-center">
          <p className='text-lato gnogen-buy-info'>CLAIMABLE EGLD</p>
        </div>
      </div>
      <div className="row d-none d-lg-flex">
        <div className="col-4 text-center">
          <button className='gnogen-button' onClick={handleLogout}>DISCONNECT WALLET</button>
        </div>
        <div className="col-8 d-grid ps-5 pe-5 text-center">
          <button className='gnogen-button' disabled={sellStage != 4 || hasClaimed || numberTicketBought === 0} onClick={sendClaimTransaction}>CLAIM ALL</button>
        </div>
      </div>

      <div className="row d-flex d-lg-none">
        <div className="col-12 text-center">
          <p className='text-lato gnogen-buy-info gnogen-yellow'>{numberTicketBought}</p>
        </div>
        <div className="col-12 text-center">
          <p className='text-lato gnogen-buy-info'>TICKETS PURCHASED</p>
        </div>
        <div className="col-12 text-center">
          <button className='gnogen-button' onClick={handleLogout}>DISCONNECT WALLET</button>
        </div>
      </div>
      <div className="row d-flex d-lg-none mt-5">
        <div className="col-6 text-center">
          <p className='text-lato gnogen-buy-info'>{sellStage == 4 ? numberOfCurrentWinningTickets : 'TBA'}</p>
        </div>
        <div className="col-6 text-center">
          <p className='text-lato gnogen-buy-info'>{sellStage == 4 ?
            <>{hasClaimed ? 0 : (numberTicketBought - numberOfCurrentWinningTickets) * ticketsPrice}</> :
            'TBA'
          }
          </p>
        </div>
        <div className="col-6 text-center">
          <p className='text-lato gnogen-buy-info'>WINNING TICKETS</p>
        </div>
        <div className="col-6 text-center">
          <p className='text-lato gnogen-buy-info'>CLAIMABLE EGLD</p>
        </div>
        <div className="col-12 d-grid ps-5 pe-5 text-center">
          <button className='gnogen-button' disabled={sellStage != 4 || hasClaimed} onClick={sendClaimTransaction}>CLAIM ALL</button>
        </div>
      </div>
    </div>


  );
};

export default Buy;