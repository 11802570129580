import Buy from 'pages/Buy';
import DirectBuy from 'pages/DirectBuy';
import Lottery from 'pages/Lottery';
import Mint from 'pages/Mint';
import Redirect from 'pages/Redirect';
import Unlock from 'pages/Unlock';
import withPageTitle from './components/PageTitle';
import Home from './pages/Home';

export const routeNames = {
  home: '/',
  unlock: '/unlock',
  buy: '/claim',
  directbuy: '/directbuy',
  redirect: '/redirect',
  mint: '/mint',
  lottery: '/lottery',
  transaction: '/transaction',
  ledger: '/ledger',
  walletconnect: '/walletconnect'
};

const routes: Array<any> = [
  {
    path: routeNames.home,
    title: 'Home',
    component: Home
  },
  {
    path: routeNames.unlock,
    title: 'Unlock',
    component: Unlock
  },
  {
    path: routeNames.buy,
    title: 'Buy',
    component: Buy,
    authenticatedRoute: true
  },
  {
    path: routeNames.lottery,
    title: 'Lottery',
    component: Lottery,
    authenticatedRoute: true
  },
  {
    path: routeNames.mint,
    title: 'Mint',
    component: Mint,
    authenticatedRoute: true
  },
  {
    path: routeNames.directbuy,
    title: 'Direct Buy',
    component: DirectBuy,
    authenticatedRoute: true
  },
  {
    path: routeNames.redirect,
    title: 'Redirecting...',
    component: Redirect,
    authenticatedRoute: true
  }
];

const mappedRoutes = routes.map((route) => {
  const title = 'GNOGEN';

  const requiresAuth = Boolean(route.authenticatedRoute);
  const wrappedComponent = withPageTitle(title, route.component);

  return {
    path: route.path,
    component: wrappedComponent,
    authenticatedRoute: requiresAuth
  };
});

export default mappedRoutes;
