export const contractAddress =
  'erd1qqqqqqqqqqqqqpgqnwk2675x9vsprkxq3arua6zjlsfxhuswmesqxlx5dj';

export const environment = 'mainnet';
export const dAppName = 'GNOGEN Lottery';

export const ticketsPrice = 0.5;
export const maxTickets = 4;
export const dogaSaleStart = 1668196800;
export const buyingPeriodStart = 1668196800;
export const winningSelectionStart = 1668369600;
export const claimingPeriodStart = 1668974400;

export const salesContractAdresses = [
  'erd1qqqqqqqqqqqqqpgqxa0dp7lqgkmaps9dsw63kkqxd0hgen2hmesqr0u2k9',
  'erd1qqqqqqqqqqqqqpgq5uulltmple4yuxk8j3uprv3djm3agdvgmesqc57542',
  'erd1qqqqqqqqqqqqqpgqlgwlqwu3vz5sm0q324pwtzwrcwjdc4tzmesqp9uqll',
  'erd1qqqqqqqqqqqqqpgq3jst9l7qcskyevuge26cwkautm6hvpj3mesq333gff',
  'erd1qqqqqqqqqqqqqpgq0duxthxj4dkvsr3y40t9atuwszd3rmkymesq6krwta',
  'erd1qqqqqqqqqqqqqpgqz53tdwrp3png8w5c0zdcm4alhd6fusrnmesqrld9t3',
  'erd1qqqqqqqqqqqqqpgqnwk2675x9vsprkxq3arua6zjlsfxhuswmesqxlx5dj'
];
