/* eslint-disable */
import * as React from 'react';
import { routeNames } from 'routes';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { getSaleStage } from 'utils/api/microservices';
import { ApiNetworkProvider } from "@elrondnetwork/erdjs-network-providers";
import { useInterval } from 'usehooks-ts';
import { useGetAccountInfo, useGetNetworkConfig, useTrackTransactionStatus } from '@elrondnetwork/dapp-core/hooks';
import { sendTransactions } from '@elrondnetwork/dapp-core/services';
import { refreshAccount } from '@elrondnetwork/dapp-core/utils/account';
import { Address, AddressValue, ContractFunction, Query, SmartContract } from '@elrondnetwork/erdjs/out';
import { contractAddress, dogaSaleStart, winningSelectionStart, buyingPeriodStart, ticketsPrice } from 'config';
import Loading from 'components/Loading';
import moment from 'moment';



const DirectBuy = () => {
  const MINT_ENABLED = true;
  const LOADING_OVER = 2;

  const { network } = useGetNetworkConfig();
  let networkProvider = new ApiNetworkProvider(network.apiAddress, {timeout: 5000});
  const { address } = useGetAccountInfo();
  const isLoggedIn = Boolean(address);
  const [transactionSessionId, setTransactionSessionId] = React.useState<string | null>(null);
  
  const [isLoading, setIsLoading] = React.useState<number>(1);

  const [isWhitelist, setIsWhitelist] = React.useState<boolean>(true);
  const [hasBuyDoga, setHasBuyDoga] = React.useState<boolean>(false);
  const [hasJustBought, setHasJustBought] = React.useState<boolean>(false);
  const [dogaSaleStartTimeLeft, setDogaSaleStartTimeLeft] = React.useState<any>(null);
  const [winningSelectionStarted, setWinningSelectionStarted] = React.useState<boolean>(false);

  const addLoading = () => {
    setIsLoading(isLoading + 1);
  }

  const calculateDrawTimeLeft = () => {
    const difference = +new Date(dogaSaleStart * 1000) - +new Date();

    let timeLeft: any = null;

    if (difference > 0) {
      timeLeft = {
        hours: Math.floor((difference / (1000 * 60 * 60))),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      };
    }

    setDogaSaleStartTimeLeft(timeLeft);
  };

  useInterval(
    () => {
      if(winningSelectionStart <= moment().unix()) {
        setWinningSelectionStarted(true);
      } 
      calculateDrawTimeLeft();
    },
    1000,
  );

  // Get if the user can direct buy
  const getIsWhitelist = async () => {
    let parsedContractAddress = new Address(contractAddress);
    let contract = new SmartContract({ address: parsedContractAddress });
    let callerAddress = new Address(address);
    
    let query = contract.createQuery({
        func: new ContractFunction("isWhitelist"),
        args: [new AddressValue(callerAddress)]
    });
    
    let queryResponse = await networkProvider.queryContract(query);
    const [encoded] = queryResponse.returnData;
    switch (encoded) {
      case undefined: setIsWhitelist(false); break;
      case '': setIsWhitelist(false); break;
      default: {
        const decoded = Buffer.from(encoded, 'base64').toString('hex');
        setIsWhitelist(parseInt(decoded, 16) > 0 ? true : false);
        break;
      }
    }
  };

  const getHasBuyDoga = async () => {
    let parsedContractAddress = new Address(contractAddress);
    let contract = new SmartContract({ address: parsedContractAddress });
    let callerAddress = new Address(address);
    
    let query = contract.createQuery({
        func: new ContractFunction("hasBuyDoga"),
        args: [new AddressValue(callerAddress)]
    });
    
    let queryResponse = await networkProvider.queryContract(query);
    const [encoded] = queryResponse.returnData;
    switch (encoded) {
      case undefined: setHasBuyDoga(false); break;
      case '': setHasBuyDoga(false); break;
      default: {
        const decoded = Buffer.from(encoded, 'base64').toString('hex');
        setHasBuyDoga(parseInt(decoded, 16) > 0 ? true : false);
        break;
      }
    }
    addLoading();
  };

  const sendPurchaseTransaction = async () => {
    const revealTransaction = {
      value: 1000000000000000000*ticketsPrice,
      data: `buySingleDoga`,
      gasLimit: 6000000,
      receiver: contractAddress
    };
    await refreshAccount();

    const { sessionId /*, error*/ } = await sendTransactions({
      transactions: revealTransaction,
      transactionsDisplayInfo: {
        processingMessage: 'Looking for your DOGA',
        errorMessage: 'An error has occured',
        successMessage: 'DOGA sale successful'
      },
      redirectAfterSign: false
    });
    if (sessionId != null) {
      setTransactionSessionId(sessionId);
    }
  };

  const transactionStatus = useTrackTransactionStatus({
    transactionId: transactionSessionId
  });

  React.useEffect(() => {
    if (transactionStatus.isSuccessful) {
      setHasJustBought(true);
    }
  }, [transactionStatus.isPending]);

  React.useEffect(() => {
    getIsWhitelist();
    getHasBuyDoga();
    if(winningSelectionStart <= moment().unix()) {
      setWinningSelectionStarted(true);
    } 
    calculateDrawTimeLeft();
  }, []);

  React.useEffect(() => {
    if (hasBuyDoga || !isWhitelist) {
      navigateToLottery();
    }
  }, [hasBuyDoga, isWhitelist, hasJustBought]);

  const navigateToLottery = () => {
    window.location.href = routeNames.lottery;
  };

  return (
    <div className='container'>
        {isLoading < LOADING_OVER ? <>{isLoading}<Loading></Loading> </>:
          <>
           <div className='row'>
            <div className='col-12'>
              <div className='p-40'>
                <p className='gnogen-text fs-1 gnogen-text-shadow'>You are eligible for <span className='gnogen-yellow'>ONE</span> direct mint. You will still be able to purchase tickets after you bought your DOGA.</p>
              </div>
            </div>
          </div>
          <div className='row'>
          <div className="col-12 gnogen-countdown_container text-center">
          <p className='gnogen-countdown'>
            { dogaSaleStartTimeLeft ?
              <>
                {`${dogaSaleStartTimeLeft.hours} : ${dogaSaleStartTimeLeft.minutes} : `}
                {dogaSaleStartTimeLeft.seconds.toString().length == 1 ? 0 : dogaSaleStartTimeLeft.seconds.toString().charAt(0)}
                <span className='gnogen-yellow'>
                  {dogaSaleStartTimeLeft.seconds.toString().length == 1 ? dogaSaleStartTimeLeft.seconds.toString().charAt(0) : dogaSaleStartTimeLeft.seconds.toString().charAt(1)}
                </span>
              </> :
              <>
              { hasJustBought || winningSelectionStarted ? <>PROCEED TO THE <span className='gnogen-yellow'>LOTTERY</span></> :
                <>SALE IS <span className='gnogen-yellow'>OPEN</span></>
              }
              </>
            }
          </p>
        </div>
          <div className='col-md-3'></div>
            <div className='col-6 col-md-3 text-center'>
              <button className='gnogen-button' disabled={dogaSaleStartTimeLeft || hasJustBought || winningSelectionStarted} onClick={sendPurchaseTransaction}>BUY NFT</button>
            </div>            
            <div className='col-6 col-md-3 text-center'>
              <button className='gnogen-button' disabled={!hasJustBought && !winningSelectionStarted} onClick={navigateToLottery}>GO TO LOTTERY</button>
            </div>
            <div className='col-md-3'></div>
          </div>
          </>
        }
      </div>
  );
};

export default DirectBuy;